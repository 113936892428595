'use client'

import Image from 'next/image'
import React, {useEffect, useMemo, useRef, useState} from 'react'

import {FeaturedImage} from '@atorie/api/admin'
import {BoundingPoly} from '@atorie/api/vision'
import {useFeaturedImages, useShopifyProductsByIdsQuery} from '@atorie/hooks'
import {AnalyzingOverlay} from '@atorie/ui/vision'

import ProductComparison from './product-comparison'

export default function Hero({
  landingScrolled,
  absoluteButtonRef,
  scrollImagesCount = 3,
}: {
  landingScrolled?: boolean
  absoluteButtonRef?: React.RefObject<HTMLButtonElement>
  scrollImagesCount?: number
}) {
  // Fetch featured images
  const {data: featuredImages} = useFeaturedImages({published: true})
  const [selectedFeaturedImage, setSelectedFeaturedImage] = useState(
    featuredImages?.[0] ?? null,
  )

  // Extract all Shopify product IDs from featured images
  const shopifyProductIds = useMemo(() => {
    return (
      featuredImages?.map(
        (image) =>
          'gid://shopify/Product/' +
          image.shopify_product_match.shopify_product_id,
      ) ?? []
    )
  }, [featuredImages])

  // Use the hook to fetch all products at once
  const {data: shopifyProducts} =
    useShopifyProductsByIdsQuery(shopifyProductIds)

  // Find the current product based on the selected featured image
  const currentShopifyProduct = useMemo(() => {
    if (!selectedFeaturedImage || !shopifyProducts) return null
    const currentProductId =
      'gid://shopify/Product/' +
      selectedFeaturedImage.shopify_product_match.shopify_product_id
    return (
      shopifyProducts.find((product) => product?.id === currentProductId) ??
      null
    )
  }, [selectedFeaturedImage, shopifyProducts])

  // Extract the price amount of the current Shopify product
  const shopifyProductAmount: string | undefined =
    currentShopifyProduct?.variants.nodes[0].price.amount

  // Refs for scroll and image container
  const scrollRef = useRef<HTMLDivElement>(null)
  const scrollPositionRef = useRef(0)
  const [centeredIndex, setCenteredIndex] = useState(
    featuredImages?.length ?? 0,
  )
  const noAnimationRef = useRef<boolean>(true)
  const imageContainerRef = useRef<HTMLDivElement>(null)
  const [boundingPoly, setBoundingPoly] = useState<BoundingPoly | null>(null)

  // Duplicate images for scrolling effect
  const duplicatedImages = [
    ...(featuredImages ?? []),
    ...(featuredImages ?? []),
    ...(featuredImages ?? []),
  ]

  // Get bounding polygon for the selected featured image
  const getBoundingPoly = (
    selectedFeaturedImage: FeaturedImage | null,
  ): BoundingPoly | null => {
    const currentFeaturedImage = selectedFeaturedImage
    if (
      !currentFeaturedImage ||
      !currentFeaturedImage.visual_search_results ||
      currentFeaturedImage.bounding_poly_index === undefined
    ) {
      return null
    }
    return (
      currentFeaturedImage.visual_search_results?.results[
        currentFeaturedImage.bounding_poly_index
      ].boundingPoly ?? null
    )
  }

  // Effect to handle image scrolling animation
  useEffect(() => {
    const scrollContainer = scrollRef.current
    const imageContainer = imageContainerRef.current
    if (!scrollContainer || !imageContainer) return

    const imageWidth = Math.floor(imageContainer.getBoundingClientRect().width)
    scrollPositionRef.current = imageWidth * ((featuredImages?.length ?? 0) - 1)
    scrollContainer.scrollLeft = scrollPositionRef.current

    const animate = () => {
      setBoundingPoly(null)

      scrollPositionRef.current += imageWidth
      const newCenteredIndex =
        Math.floor(scrollPositionRef.current / imageWidth) %
        ((featuredImages?.length ?? 0) * 2)

      if (newCenteredIndex === 0) {
        setTimeout(() => {
          noAnimationRef.current = true
          scrollPositionRef.current = (featuredImages?.length ?? 0) * imageWidth
          setCenteredIndex(featuredImages?.length ?? 0)
          updateScrollPosition()
        }, 500)
      }

      setCenteredIndex(newCenteredIndex)
      const newFeaturedImageIndex =
        newCenteredIndex % (featuredImages?.length ?? 0)
      const newFeaturedImage = featuredImages?.[newFeaturedImageIndex] ?? null
      setSelectedFeaturedImage(newFeaturedImage)
      setTimeout(() => {
        setBoundingPoly(getBoundingPoly(newFeaturedImage))
      }, 1000)
      updateScrollPosition()
    }

    const updateScrollPosition = () => {
      if (scrollContainer) {
        if (noAnimationRef.current && scrollPositionRef.current != 0) {
          noAnimationRef.current = false
          scrollContainer.style.scrollBehavior = 'auto'
        } else {
          scrollContainer.style.scrollBehavior = 'smooth'
        }
        scrollContainer.scrollLeft = scrollPositionRef.current
      }
    }

    animate()
    const animationInterval = setInterval(animate, 6000) // Default duration between images in ms

    return () => clearInterval(animationInterval)
  }, [featuredImages])

  return (
    <div className="avenir fadein-150 pt-36 text-zinc-800 lg:pt-56">
      <div className="text-sm sm:text-lg">
        <h1 className="pb-4 text-center text-3xl lg:text-4xl">
          Luxury fashion <span className="font-bold">directly</span>
          <br /> from your <span className="font-bold">brands factory</span>
        </h1>
        <div className=" mt-0.5 flex flex-col items-center justify-center text-center text-base font-thin text-zinc-800 ">
          High-quality materials, factory prices. <br /> Find products now.
        </div>
        <div className="relative mb-28 mt-5 flex h-12 flex-col items-center justify-center lg:mb-44">
          <button
            onClick={() =>
              !landingScrolled && absoluteButtonRef?.current?.click()
            }
            className="h-full w-52"
          />
        </div>
        <div className="relative flex flex-col items-center justify-center bg-stone-100 py-6 pb-8">
          <div className="mb-4 flex flex-col items-center justify-center text-center text-xl font-thin text-zinc-800/80">
            How it works
          </div>
          <div
            className={`absolute top-[68px] z-20 flex w-full flex-col items-center justify-center text-center font-normal text-zinc-800  transition-opacity duration-300 ${landingScrolled ? 'opacity-100 delay-500' : 'opacity-0'}`}
          >
            <div
              className="mt-3 rounded-full bg-white px-3 py-1.5 text-sm shadow lg:text-base"
              onClick={() => absoluteButtonRef?.current?.click()}
            >
              1. Upload a product you like
            </div>
          </div>
          <div
            className={`pointer-events-none relative size-full overflow-hidden transition-opacity duration-300 ${featuredImages ? 'opacity-100' : 'opacity-0'}`}
          >
            <div
              ref={scrollRef}
              className="no-scrollbar relative flex overflow-auto"
            >
              <div className="w-[calc(50svw-144px)] shrink-0 sm:w-[calc(50svw-192px)]" />
              {duplicatedImages.map((image, index) => (
                <div
                  key={index}
                  className="relative aspect-square w-72 shrink-0 px-4 py-1 sm:w-96 sm:px-8"
                  ref={index === 0 ? imageContainerRef : null}
                >
                  <Image
                    src={image.image_url}
                    alt={`Gallery image ${(index % (featuredImages?.length ?? 0)) + 1}`}
                    className={`size-full object-cover transition-all ${index == centeredIndex ? '' : 'scale-95 rounded-lg opacity-70'}`}
                    width={220} // Adjust width as needed
                    height={220} // Adjust height as needed
                    loading="lazy"
                    sizes="(max-width: 640px) 50vw, 20vw"
                  />
                </div>
              ))}
            </div>
            <div className="absolute left-0 top-0 z-10 flex size-full items-center justify-center transition-opacity duration-300">
              <div className="size-full w-72 px-3 sm:w-96 sm:px-7">
                <AnalyzingOverlay boundingPoly={boundingPoly} />
              </div>
            </div>
          </div>
          <div
            className={`transition-opacity duration-300 ${featuredImages ? 'opacity-100' : 'opacity-0'}`}
          >
            <div className="relative top-0 z-20 my-5 flex w-full flex-col items-center justify-center text-center text-sm font-normal text-zinc-800 lg:pt-3 lg:text-base">
              <div className="rounded-full bg-white px-3 py-1.5 shadow">
                2. We&apos;ll find matches
              </div>
            </div>
            <ProductComparison
              selectedFeaturedImage={selectedFeaturedImage}
              currentShopifyProduct={currentShopifyProduct}
              showInstruction
            />
            <div className="mt-16 flex flex-col items-center justify-center text-center text-sm font-thin text-zinc-800/80 lg:text-xl">
              It&apos;s that simple.
              <br />
              Get searching and find the same products you love
              <br />
              for a fraction of the price.
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
