'use client'

import {useEffect} from 'react'

import ShopifyProducts from '@/components/shopify/shopify-product-list'
import mixpanelService from '@atorie/api/mixpanel'

export default function Home() {
  useEffect(() => {
    mixpanelService.track('FEED_VIEWED')
  }, [])
  return <ShopifyProducts />
}
